import { burger } from "./modules/header/burger.js";
import { slider } from "./modules/slider.js";
import { faq } from "./modules/faq.js";
import { modals } from "./modules/modals.js";
import { popup } from "./modules/popup.js";
document.addEventListener("DOMContentLoaded", function (event) {
  modals();
  popup();
  faq();
  burger();
  slider();
});
