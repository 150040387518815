export const popup = () => {
  $(".buttons").click(function () {
    $(".overlay").fadeIn();
  });

  // Закриття попапа по кліку на хрестик
  $(".close-popup").click(function () {
    $(".overlay").fadeOut();
  });

  // Закриття попапа при кліку поза ним
  $(document).mouseup(function (e) {
    var popup = $(".popup");
    if (e.target != popup[0] && popup.has(e.target).length === 0) {
      $(".overlay").fadeOut();
    }
  });

  // Закриття попапа по натисканню на клавішу Esc
  $(document).keydown(function (e) {
    if (e.which === 27) {
      // Код клавіші Esc
      $(".overlay").fadeOut();
    }
  });
};
