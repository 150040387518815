export const faq = () => {
  $(".real-estate-item-list-item-title-container").on("click", function () {
    $(this).toggleClass("active");
    $(this)
      .siblings(".real-estate-item-list-item-content")
      .each(function () {
        $(this).slideToggle(300);
      });
    $(this)
      .closest(".real-estate-item-list-item")
      .siblings(".real-estate-item-list-item")
      .each(function () {
        $(this)
          .find(".real-estate-item-list-item-title-container")
          .removeClass("active");
        $(this).find(".real-estate-item-list-item-content").slideUp(300);
      });
  });
};
