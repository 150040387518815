export const slider = () => {
  let swiper = new Swiper(".home_results_item_box", {
    slidesPerView: 1,
    loop: true,
    speed: 900,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });
};
